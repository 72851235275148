import { useDispatch, useSelector } from "react-redux";
import JobsCalender from "../../components/jobsCalender";
import "./DashBoard.css";
import React, { useEffect, useState } from "react";
import {
  getJobByweek,
  getJobData,
  getNextWeekData,
} from "../../redux/actions/DashBoardActions";
import { convertTodayDate } from "../../utils/HelpingFuntions";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FiRefreshCcw } from "react-icons/fi";
import { VscClearAll } from "react-icons/vsc";
import PrimaryBtn from "../../components/PrimaryBtn";

const DashBoard = () => {
  const [state, setState] = useState("Weekly");
  const dispatch = useDispatch();
  const isLoader = useSelector((state) => state?.dashBoard?.isLoading);
  const [isHovering, setIsHovering] = useState({
    isRefresh: false,
    isReset: false,
  });
  const [selectedDate, setSelectedDate] = useState(
    convertTodayDate(new Date())
  );

  const date = convertTodayDate(new Date());

  const handleMouseOver = (refresh) => {
    if (refresh === "Refresh") {
      setIsHovering({ isRefresh: true });
    } else {
      setIsHovering({ isReset: true });
    }
  };

  const handleMouseOut = (reset) => {
    if (reset === "Reset") {
      setIsHovering({ isRefresh: false });
    } else {
      setIsHovering({ isReset: false });
    }
  };

  // useEffect(() => {
  //   dispatch(getJobByweek(selectedDate));
  // }, []);

  useEffect(() => {
    if (state === "Weekly") {
      const currentDate = convertTodayDate(new Date()); 
      setSelectedDate(currentDate);
      dispatch(getJobByweek(currentDate));
    }
  }, [state]);

  const handleRightArrowClick = () => {
    const daysToAdd = state === "Weekly" ? 7 : 1;
    let date = new Date(selectedDate);
    date = convertTodayDate(new Date(date.setDate(date.getDate() + daysToAdd)));
    setSelectedDate(date);
    if (state === "Weekly") {
      dispatch(getJobByweek(date));
    } 
  };

  const handleLeftArrowClick = () => {
    const daysToSub = state === "Weekly" ? 7 : 1;
    let date = new Date(selectedDate);
    date = convertTodayDate(new Date(date.setDate(date.getDate() - daysToSub)));
    setSelectedDate(date);
    if (state === "Weekly") {
      dispatch(getJobByweek(date));
    } 
  };

  console.log(selectedDate, "DATE");

  return (
    <>
      <div className="heading-container">
        <h2>DASHBOARD</h2>
        <div className="mainContiner">
          <div className="iconContainer">
            <input
              value={selectedDate}
              type="date"
              className="newDate"
              onChange={(e) => {
                setSelectedDate(e?.target?.value);
                setState("Weekly");
              }}
            />
            <PrimaryBtn
              width={110}
              height={30}
              title={"Search Job"}
              onclick={() => {
                if (state == "Weekly") dispatch(getJobByweek(selectedDate));
                setState("Weekly");
              }}
            />
            <span
              onClick={() => {
                if (state == "Weekly") setState("Weekly");
                if (state == "Weekly") dispatch(getJobByweek(selectedDate));
                if (state == "Daily") dispatch(getJobData(date));
              }}
              className="Left"
              onMouseOver={() => {
                handleMouseOver("Refresh");
              }}
              onMouseOut={() => {
                handleMouseOut("Reset");
              }}
            >
              <FiRefreshCcw size={17} color="#DC2F2B" />
              {isHovering?.isRefresh && <span className="hover">Refresh</span>}
            </span>

            <span
              className="Left"
              onClick={() => {
                setState("Weekly");
                const currentDate = convertTodayDate(new Date()); // Get the current date
                setSelectedDate(currentDate);
                dispatch(getJobByweek(currentDate));
              }}
              onMouseOver={() => {
                handleMouseOver("");
              }}
              onMouseOut={() => {
                handleMouseOut("");
              }}
            >
              <VscClearAll size={18} color="#DC2F2B" />
              {isHovering?.isReset && <span className="hover">Reset</span>}
            </span>
            {state == "Weekly" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <span className="Left" onClick={handleLeftArrowClick}>
                  <AiOutlineLeft size={17.5} color="#DC2F2B" />
                </span>
                <span onClick={handleRightArrowClick} className="Left">
                  <AiOutlineRight size={17.5} color="#DC2F2B" />
                </span>
              </div>
            )}
          </div>
          {!isLoader ? (
            <>
              <div className="radio-input">
                <input
                  type="radio"
                  id="Weekly"
                  name="timePeriod"
                  readOnly
                  value={"Weekly"}
                  checked={state === "Weekly"}
                  onClick={() => {
                    setState("Weekly");
                    // dispatch(getJobByweek(selectedDate));
                  }}
                />
                <label style={{ marginRight: 4 }}>Weekly</label>
                <input
                  checked={state === "Daily"}
                  type="radio"
                  id="Daily"
                  name="timePeriod"
                  readOnly
                  value={"Daily"}
                  onClick={() => {
                    setState("Daily");
                    dispatch(getJobData(date));
                    setSelectedDate("");
                  }}
                />
                <label>Daily</label>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <JobsCalender
        tablestyling={"table-stly"}
        parentStyling={"table-wrapper"}
        data={{ state, selectedDate, date }}
      />
    </>
  );
};

export default DashBoard;
