import axios from "axios";
import {
  ALL_JOB_DOWNLOADING_PDF,
  EXPORT_TO_EXCEL,
  FILTER_PAY_DUTY_FORM,
  FILTER_PAY_DUTY_FORM_LOADING,
  FILTER_RIGGER_DATA_LOADING,
  FILTER_TRANSPORTATION_TICKETS_DATA_LOADING,
  GENERATE_PDF,
  IS_TICKETS_DATA_LOADING,
  PAY_DUTY_FORMS_DATA,
  PDF_FILE_URL,
  RIGGER_TICKETS,
  TRANSPORTATION_TICKETS,
} from "../reducers/TicketsReducers";
import { EndPoints, baseUrl, baseUrl2 } from "../../utils/Api";

// *****************RIGGER**********************

//GETTING_RIGEER_TICKETS
export const getRiggerTicketsData = (showLoginDetails) => {
  return (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      axios
        .get(`${baseUrl2}${EndPoints.getRiggerTickets}/${showLoginDetails}`)
        .then((res) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
          dispatch({ type: RIGGER_TICKETS, payload: res?.data?.data });
        })
        .catch((err) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
        });
    } catch (error) {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
    }
  };
};

//DOWNLOAD URL FOR EXCEL_DATA
export const downloadTicketsData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: true });

      const response = await axios.get(`${baseUrl2}${EndPoints.riggerExcelData}`, { responseType: 'blob' });

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'RiggerData.xlsx'; 
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      dispatch({ type: PDF_FILE_URL, payload: data });
    } catch (error) {
      console.error("Error downloading Excel file:", error);

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });
      // Handle additional error scenarios if needed
    }
  };
};

//GENRATE EMAIL PDF FOR RIGGER 
export const generatePdf = (data, id, ticketNumber) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GENERATE_PDF, payload: true });

      // Convert data object to URL parameters
      const response = await axios.get(`${baseUrl2}${EndPoints.riggerpdf}/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const dataUri = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = dataUri;
        a.download = `${ticketNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({ type: GENERATE_PDF, payload: false });
      } else {
        alert(response.data?.error);
        dispatch({ type: GENERATE_PDF, payload: false });
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      dispatch({ type: GENERATE_PDF, payload: false });
    }
  };
};

// FILTER_PARAMS_DATA
export const filterRiggerTickets = (data) => {
  return async (dispatch) => {
    dispatch({ type: FILTER_RIGGER_DATA_LOADING, payload: true });
    try {
      const rawResponse = await fetch(
        `${baseUrl2}${EndPoints.filterRiggerTicketsData}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const content = await rawResponse.json();
      if (rawResponse?.status == 200) {
        dispatch({ type: FILTER_RIGGER_DATA_LOADING, payload: false });
        dispatch({ type: RIGGER_TICKETS, payload: content });
      } else {
        alert(content?.message);
        dispatch({ type: FILTER_RIGGER_DATA_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: FILTER_RIGGER_DATA_LOADING, payload: false });
    }
  };
};

// *****************TRANSPORATION**********************

//GETTING_TRANSPORTATION_TICKETS
export const getTransportationTicketsData = () => {
  return (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      axios
        .get(`${baseUrl2}${EndPoints.getTransportationTickets}`)
        .then((res) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
          dispatch({ type: TRANSPORTATION_TICKETS, payload: res?.data?.data });
        })
        .catch((err) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
        });
    } catch (error) {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
    }
  };
};

//FILTER_TRANPORTATION_DATA
export const filterTransportationTicketsData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FILTER_TRANSPORTATION_TICKETS_DATA_LOADING,
        payload: true,
      });
      const rawResponse = await fetch(
        `${baseUrl2}${EndPoints.filterTransportationTickets}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const content = await rawResponse.json();
      if (rawResponse?.status == 200) {
        dispatch({
          type: FILTER_TRANSPORTATION_TICKETS_DATA_LOADING,
          payload: false,
        });
        dispatch({ type: TRANSPORTATION_TICKETS, payload: content });
      } else {
        alert(content?.message);
        dispatch({
          type: FILTER_TRANSPORTATION_TICKETS_DATA_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      dispatch({
        type: FILTER_TRANSPORTATION_TICKETS_DATA_LOADING,
        payload: false,
      });
    }
  };
};

//DOWNLOAD URL FOR TRANS EXCEL_DATA
export const downloadTransTicketsData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: true });

      const response = await axios.get(`${baseUrl2}${EndPoints.transportationExcel}`, { responseType: 'blob' });

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'TransportationData.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      dispatch({ type: PDF_FILE_URL, payload: data });
    } catch (error) {
      console.error("Error downloading Excel file:", error);

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });
      // Handle additional error scenarios if needed
    }
  };
};

//GENRATE EMAIL PDF FOR TRANSPORATION
export const generateTransPdf = (data, id, ticketNumber) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GENERATE_PDF, payload: true });

      const response = await axios.get(`${baseUrl2}${EndPoints.transportationPdf}/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const dataUri = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = dataUri;
        a.download = `${ticketNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({ type: GENERATE_PDF, payload: false });
      } else {
        alert(response.data?.errors);
        dispatch({ type: GENERATE_PDF, payload: false });
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      dispatch({ type: GENERATE_PDF, payload: false });
    }
  };
};

// *****************PAYDUTY**********************

//Get_Pay_DutyForms_Data
export const getPayDutyFormsData = (showLoginDetails) => {
  return (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      axios
        .get(`${baseUrl2}${EndPoints.getPayDutyForm}/${showLoginDetails}`)
        .then((res) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
          dispatch({ type: PAY_DUTY_FORMS_DATA, payload: res?.data?.data });
        })
        .catch((err) => {
          dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
        });
    } catch (error) {
      dispatch({
        type: IS_TICKETS_DATA_LOADING,
        payload: false,
      });
    }
  };
};

//FILTER PAY_DUTY FORM
export const getFilterPayDutyData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FILTER_PAY_DUTY_FORM_LOADING,
        payload: true,
      });
      const rawResponse = await fetch(
        `${baseUrl2}${EndPoints.filterPaydutyData}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const content = await rawResponse.json();
      if (rawResponse?.status == 200) {
        dispatch({ type: FILTER_PAY_DUTY_FORM_LOADING, payload: false });
        dispatch({ type: PAY_DUTY_FORMS_DATA, payload: content });
      } else {
        alert(content?.message);
        dispatch({ type: FILTER_PAY_DUTY_FORM_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({
        type: FILTER_PAY_DUTY_FORM_LOADING,
        payload: false,
      });
    }
  };
};

export const generatePayDutyPdf = (data, id, ticketNumber) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GENERATE_PDF, payload: true });

      const response = await axios.get(`${baseUrl2}${EndPoints.paydutyPdf}/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const dataUri = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = dataUri;
        a.download = `${ticketNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({ type: GENERATE_PDF, payload: false });
      } else {
        alert(response.data?.errors);
        dispatch({ type: GENERATE_PDF, payload: false });
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      dispatch({ type: GENERATE_PDF, payload: false });
    }
  };
};
