import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import PayDutyFilterCard from "../../components/PayDutyFilterCard";
import PayDutyFormTabel from "../../components/PayDutyForm";
import { PayDutyFormDummyData, optionList } from "../../utils/dummyData";
import ReactLoading from "react-loading";
import { useEffect } from "react";
import { getPayDutyFormsData } from "../../redux/actions/TicketSAction";

const PayDutyForm = () => {
  const riggerTicket = useSelector((state) => state?.ticketsData);
  console.log(riggerTicket?.payDutyForm, "TICKET");
  const dispatch = useDispatch();
  const newArr = riggerTicket?.payDutyForm?.map((item) => ({
    Date: item?.date,
    Location: item?.location,
    "Start Time": item?.startTime,
    "End Time": item?.finishTime,
    "Total Hours": item?.totalHours,
    Email: item?.emailAddress,
    "Officer Name": item?.officerName,
    Officer: item?.officer,
    Division: item?.division,
    Signature: item?.signature ? item?.signature : "No",
  }));
  const myData = localStorage.getItem('items')
  const parsedData = JSON.parse(myData);

  useEffect(() => {
    dispatch(getPayDutyFormsData(parsedData.id));
  }, []);

  return (
    <div
      style={{
        marginTop: "2rem",
      }}
    >
      <div className="title-class">
        <h3>FILTER</h3>
      </div>
      <div>
        <PayDutyFilterCard
          Name={"Officer Name"}
          Address={"Address"}
          Date={"Date"}
        />
      </div>
      <h3 style={{ marginLeft: "2.5rem" }}>PAY DUTY FORM</h3>
      <div>
        <PayDutyFormTabel payDutyData={PayDutyFormDummyData} />
      </div>
    </div>
  );
};

export default PayDutyForm;
