import axios from "axios";
import {
  FORWARD_WEEK_JOBS,
  GET_ALLJOB,
  JOB_EDIT,
  JOB_EDIT_LOADING,
  LOADING_STATE,
} from "../reducers/DashBoardReducers";
import { baseUrl, baseUrl2 } from "../../utils/Api";
import { EndPoints } from "../../utils/Api";
import {
  ALL_JOB_DOWNLOADING_PDF,
  FILTER_RIGGER_DATA_LOADING,
  IS_TICKETS_DATA_LOADING,
  PDF_FILE_URL,
} from "../reducers/TicketsReducers";

//Get All Jobs data
export const getJobData = (date) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_STATE, payload: true });
    const url =
      date !== undefined
        ? `${baseUrl2}${EndPoints.getAllJobs}?jobDate=${date}`
        : `${baseUrl2}${EndPoints.getAllJobs}`;
    try {
      axios
        .get(url)
        .then((res) => {
          dispatch({ type: LOADING_STATE, payload: false });
          dispatch({ type: GET_ALLJOB, payload: res?.data?.data });
        })
        .catch((err) => {
          dispatch({ type: LOADING_STATE, payload: false });
        });
    } catch (error) {
      dispatch({ type: LOADING_STATE, payload: false });
    }
  };
};

//DOWNLOAD URL FOR EXCEL_DATA
export const downloadJobTicketsData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: true });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: true });

      const response = await axios.get(
        `${baseUrl2}${EndPoints.exportExcelData}`,
        { responseType: "blob" }
      );

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "JobData.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      dispatch({ type: PDF_FILE_URL, payload: data });
    } catch (error) {
      console.error("Error downloading Excel file:", error);

      dispatch({ type: IS_TICKETS_DATA_LOADING, payload: false });
      dispatch({ type: ALL_JOB_DOWNLOADING_PDF, payload: false });
    }
  };
};

//Get Job By weeks
export const getJobByweek = (date) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_STATE, payload: true });
    try {
      axios
        .get(`${baseUrl2}${EndPoints.getJoByWeek}?jobDate=${date}`)
        .then((res) => {
          dispatch({ type: LOADING_STATE, payload: false });
          dispatch({ type: GET_ALLJOB, payload: res?.data?.jobsInWeek });
        })
        .catch((err) => {
          dispatch({ type: LOADING_STATE, payload: false });
        });
    } catch (error) {
      dispatch({ type: LOADING_STATE, payload: false });
    }
  };
};

//Get next week Job By weeks
export const getNextWeekData = (date) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_STATE, payload: true });
    try {
      axios
        .get(`${baseUrl2}${EndPoints.forwardWeekJobs}?jobDate=${date}`)
        .then((res) => {
          dispatch({ type: LOADING_STATE, payload: false });
          dispatch({ type: GET_ALLJOB, payload: res?.data?.jobsInWeek });
          dispatch({ type: FORWARD_WEEK_JOBS, payload: res?.data });
        })
        .catch((err) => {
          dispatch({ type: LOADING_STATE, payload: false });
        });
    } catch (error) {
      dispatch({ type: LOADING_STATE, payload: false });
    }
  };
};

//FILTER_JOBS_DATA_BY_PARAMS
export const filterJobByParam = (data) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_STATE, payload: true });
    try {
      const rawResponse = await fetch(`${baseUrl2}${EndPoints.jobFilter}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const content = await rawResponse.json();
      if (rawResponse?.status == 200) {
        dispatch({ type: LOADING_STATE, payload: false });
        dispatch({ type: GET_ALLJOB, payload: content });
      } else {
        alert(content?.message);
        dispatch({ type: LOADING_STATE, payload: false });
      }
    } catch (error) {
      dispatch({ type: LOADING_STATE, payload: false });
    }
  };
};

//Edit Jobs
export const editJob = (editData, isScci, jobId, images, onclose) => {
  const items = JSON.parse(localStorage.getItem("items"));
  const formData = new FormData();

  if (editData?.clientName !== undefined) {
    formData.append("clientName", editData.clientName);
  }

  if (editData?.jobDate !== undefined) {
    formData.append("jobDate", editData?.jobDate);
  }

  if (editData?.jobTime !== undefined) {
    formData.append("jobTime", editData?.jobTime);
  }

  if (editData?.address !== undefined) {
    formData.append("address", editData?.address);
  }

  if (editData?.equipmentToBeUsed !== undefined) {
    formData.append("equipmentToBeUsed", editData?.equipmentToBeUsed);
  }

  if (editData?.supplierName !== undefined) {
    formData.append("supplierName", editData?.supplierName);
  }

  if (editData?.notes !== undefined) {
    formData.append("notes", editData?.notes);
  }
  
  if (editData?.statusCode !== undefined) {
    formData.append("statusCode", editData?.statusCode || 'goodTogo');
  }

  if (editData?.riggerAssigned !== undefined) {
    formData.append("riggerAssigned", editData?.riggerAssigned);
  }

  formData.append("isSCCI", isScci ? 1 : 0);
  images?.forEach((item, index) => {
    const imageName = `imageFiles[${index}]`;
    formData.append(imageName, item); 
  });
  formData.append("enterBy", items?.name);
  formData.append("userId", items?.id);

  return async (dispatch) => {
    try {
      dispatch({ type: JOB_EDIT_LOADING, payload: true });
      const rawResponse = await fetch(
       ` ${baseUrl2}${EndPoints.jobPost}/${jobId}?_method=PUT`,
        {
          method: "POST",
          body: formData,
        }
      );

      const content = await rawResponse.json();
      if (rawResponse?.status == 200) {
        alert(content?.message);
        onclose(() => {
          return false;
        });
        dispatch({ type: JOB_EDIT_LOADING, payload: false });
        dispatch({ type: JOB_EDIT, payload: content?.data });
      } else {
        alert(content?.errors);
        dispatch({ type: JOB_EDIT_LOADING, payload: false });
      }
    } catch (editJob) {
      dispatch({ type: JOB_EDIT_LOADING, payload: false });
    }
  };
};

export const uploadImages = (images) => {
  return async () => {
    var formData = new FormData();
    images?.map((item) => {
      return formData.append("imageFiles", item, item?.name);
    });
    try {
      const response = await fetch(`${baseUrl2}${EndPoints.imageUploads}`, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      });
      const finalResponse = await response.json();
    } catch (error) {}
  };
};
