export const baseUrl = "https://test.scserver.org/";
export const baseUrl2 = "https://test.visech.com/api/";

export const EndPoints = {
  login: "web-login", // login from website
  forgetPassword: "update-password", // forget Passsword from web
  sendOtp: "send-otp", // sending opt from web
  verifyOtp: "verify-otp", // verification of the otp
  getAllJobs: "jobs", //All Jobs
  getAllUsers: "users", // All getusers
  getWebUsers: "users", // web users
  updateUser: "edit-user", //For the Admins:
  editUser: "edit-user", //For the Managers and Basic Users:
  getJoByWeek: "jobweek", //week jobs
  jobPost:'edit-job',
  jobs: 'create-job',
  jobFilter:'jobfilter',// Job filter by Name,Address,Date
  imageUploads:'upload', //Picture Upload
  adminCreation:'create-user', //Create NEW Admin
  managerCreation:'create-user', //Creation NEW Manager
  getRiggerTickets:'rigger-tickets', //To Get Rigger Tickets
  exportExcelData:'job-excel', //Export Excel Data
  filterRiggerTicketsData:'riggerfilter', //Filter Rigger Data
  getTransportationTickets:'transportations', //To Get Transportation Tickets
  filterTransportationTickets:'transfilter', //Filter Transportation Datas
  getPayDutyForm:'pay-duty', //getPay Duty Forms
  filterPaydutyData:"dutyfilter", //FIlter Pay Duty Forms
  logOutUser:'logout', //For Logout ,
  generateDownload:'pdf', //Generate Download pdf
  forwardWeekJobs:'jobweekadd', //After week data
  riggerExcelData: 'rigger-excel',
  riggerpdf: 'rigger-pdf',
  transportationPdf: 'transportation-pdf',
  transportationExcel: 'transportation-excel',
  paydutyPdf:'payduty-pdf'
};
