import React, { useState } from "react";
import JobListCard from "./JobListCard";
import "../pages/riggerTickets/Riggertickets.css";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import NotificationModal from "./WarningModal";
import PrimaryBtn from "./PrimaryBtn";
import Modal from "./CustomModal/CustomModal";
import { generatePdf, generateTransPdf } from "../redux/actions/TicketSAction";

const transportTableData = [
  "Pickup Address",
  "Billing Address", //Billinng ADD
  "Time In",
  "Time Out",
  "Notes",
  "Job Number",
  "Special Instructions",
  "P.O. Number",
  "Special Instructions",
  "Site Contact Name",
  "Special Instructions",
  "Site Contact Number",
  "Special Instructions",
  "Shipper Name",
  "Signature",
  "Date",
  "Time In",
  "Time Out",
  "Pickup Driver Name",
  "Signature",
  "Date",
  "Time In",
  "Time Out",
  "Images",
  "Customer Name",
  "Customer Email",
  "Signature",
  "Date",
  "Time In",
  "Time Out",
  "Draft",
  // "Images",
  "Download Pdf",
];

const TransportationTicketsTable = ({ transportaionTable }) => {
  const dispatch = useDispatch();
  const transportationTickets = useSelector((state) => state?.ticketsData);
  const [openModal, setOpenModal] = useState(false);
  const [selsctedArray, setSelectedArry] = useState([]);
  const [selectedBtnId, setSelectedBtnId] = useState(-1);

  return (
    <>
      {transportationTickets?.isTicketsDataLoading ? (
        <NotificationModal />
      ) : null}
      <div className="table-main-card">
        <div className="hack1">
          <div className="hack2">
            {transportationTickets?.isTicketsDataLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type="spin"
                  color={"red"}
                  height={100}
                  width={100}
                  delay={4}
                />
              </div>
            ) : (
              <>
                {transportationTickets?.transportationTicketsData?.length ==
                0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1>No Transportation Tickets Found</h1>
                  </div>
                ) : (
                  <table className="rigger-table">
                    <tr>
                      {transportTableData.map((item) => {
                        return (
                          <>
                            <th className="th-styling">{item}</th>
                          </>
                        );
                      })}
                    </tr>
                    {transportationTickets?.transportationTicketsData?.map(
                      (item, index) => {
                        return (
                          <>
                            <tr>
                              <th className="item-styling">
                                {item.pickupAddress}
                              </th>
                              <th className="item-styling">
                                {item.billingAddress}
                              </th>
                              <th className="item-styling">{item.TimeIn}</th>
                              <th className="item-styling">{item.TimeOut}</th>
                              <th className="item-styling">{item.notes}</th>
                              <th className="item-styling">{item.jobNumber}</th>
                              <th className="item-styling">
                                {item.specialInstructionsForJobNumber}
                              </th>
                              <th className="item-styling">{item.poNumber}</th>
                              <th className="item-styling">
                                {item.specialInstructionsForPoNumber}
                              </th>
                              
                              <th className="item-styling">
                                {item.siteContactName}
                              </th>
                              <th className="item-styling">
                                {item.specialInstructionsForSiteContactName}
                              </th>
                              <th className="item-styling">
                                {item.siteContactNumber}
                              </th>
                              <th className="item-styling">
                                {item.specialInstructionsForSiteContactNumber}
                              </th>
                              <th className="item-styling">
                                {item.shipperName}
                              </th>
                              <th className="item-styling">
                                {item?.shipperSignature != null ? (
                                  <>
                                    <div>
                                      <PrimaryBtn
                                        title={"Show"}
                                        onclick={() => {
                                          setOpenModal(true);
                                          setSelectedArry([
                                            item?.shipperSignature,
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <Modal
                                      open={openModal}
                                      onClose={() => setOpenModal(false)}
                                      arrr={selsctedArray}
                                    />
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    NO
                                  </p>
                                )}
                              </th>
                              <th className="item-styling">
                                {item.shipperDate}
                              </th>
                              <th className="item-styling">
                                {item.shipperTimeIn}
                              </th>
                              <th className="item-styling">
                                {item.shipperTimeOut}
                              </th>
                              <th className="item-styling">
                                {item.pickupDriverName}
                              </th>
                              <th className="item-styling">
                                {item?.driverSignature != null ? (
                                  <>
                                    <div>
                                      <PrimaryBtn
                                        title={"Show"}
                                        onclick={() => {
                                          setOpenModal(true);
                                          setSelectedArry([
                                            item?.driverSignature,
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <Modal
                                      open={openModal}
                                      onClose={() => setOpenModal(false)}
                                      arrr={selsctedArray}
                                    />
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    NO
                                  </p>
                                )}
                              </th>
                              <th className="item-styling">
                                {item.pickupDriverDate}
                              </th>
                              <th className="item-styling">
                                {item.pickupDriverTimeIn}
                              </th>
                              <th className="item-styling">
                                {item.pickupDriverTimeOut}
                              </th>
                              <td className="item-styling">
                              {item.imageFiles?.length > 0 ? (
                                <>
                                  <PrimaryBtn
                                    title={"Show"}
                                    onclick={() => {
                                      setOpenModal(true);
                                      setSelectedArry(item?.imageFiles);
                                    }}
                                  />
                                  <Modal
                                    isLoader={true}
                                    open={openModal}
                                    onClose={() => setOpenModal(false)}
                                    arrr={selsctedArray}
                                  />
                                </>
                              ) : (
                                <p>No Images</p>
                              )}
                            </td>
                              <th className="item-styling">
                                {item.customerName}
                              </th>
                              <th className="item-styling">
                                {item.customerEmail}
                              </th>
                              <th className="item-styling">
                                {item?.customerSignature != null ? (
                                  <>
                                    <div>
                                      <PrimaryBtn
                                        title={"Show"}
                                        onclick={() => {
                                          setOpenModal(true);
                                          setSelectedArry([
                                            item?.customerSignature,
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <Modal
                                      open={openModal}
                                      onClose={() => setOpenModal(false)}
                                      arrr={selsctedArray}
                                    />
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                    }}
                                  >
                                    NO
                                  </p>
                                )}
                              </th>
                              <th className="item-styling">
                                {item.customerDate}
                              </th>
                              <th className="item-styling">
                                {item.customerTimeIn}
                              </th>
                              <th className="item-styling">
                                {item.customerTimeOut}
                              </th>
                              <th className="item-styling">
                                {item.isDraft ? "Yes" : "No"}
                              </th>

                              {/* <th className="item-styling">{"IMAGES"}</th> */}
                              <th
                                style={{ borderRight: "1px solid  #0000000d" }}
                              >
                                <div key={index}>
                                  <PrimaryBtn
                                    title={
                                      transportationTickets?.isGeneratingPdf &&
                                      selectedBtnId === index ? (
                                        <div>
                                          <ReactLoading
                                            type="bars"
                                            color={"white"}
                                            height={30}
                                            width={25}
                                            delay={4}
                                          />
                                        </div>
                                      ) : (
                                        "Download"
                                      )
                                    }
                                    onclick={() => {
                                      setSelectedBtnId(index);
                                      const newData = {
                                        "Pickup Address": item?.pickupAddress,
                                        "Delivery Address":
                                          item?.billingAddress,
                                        "Time In": item?.timeInfortrans,
                                        "Time Out": item?.timeOutfortrans,
                                        Notes: item?.notes,
                                        "Job Number": item?.jobNumber,
                                        "Special Instructions For Job":
                                          item?.specialInstructionsforjob,
                                        "P.O. Number": item?.poNumber,
                                        "Special Instructions For P.O. Number":
                                          item?.specialInstructionsforpo,
                                        "Site Contact Name":
                                          item?.siteContactName,
                                        "Special Instructions For Contact Name ":
                                          item?.specialInstructionsforconName,
                                        "Site Contact Number":
                                          item.siteContactNumber,
                                        "Special Instructions For Contact Number":
                                          item?.specialInstructionsforconNo,
                                        "Shipper Name": item?.shipperName,

                                        "Date For Shipper":
                                          item?.dateforshipper,
                                        "Time In For Shipper":
                                          item?.timeInforshipper,
                                        "Time Out For Shipper":
                                          item?.timeOutforshipper,
                                        "Pickup Driver Name":
                                          item?.pickUpDriverName,

                                        "Date For Driver": item?.datefordriver,
                                        "Time In Driver": item?.timeInfordriver,
                                        "Time Out Driver":
                                          item?.timeOutfordriver,
                                        "Customer Name": item?.customerName,
                                        "Customer Email": item?.customerEmail,
                                        imagePaths: [
                                          item?.signatureforcustomer
                                            ? item?.signatureforcustomer
                                            : "sign.jpg",
                                          item?.signatureforshipper
                                            ? item?.signatureforshipper
                                            : "sign.jpg",
                                          item?.signaturefordriver
                                            ? item?.signaturefordriver
                                            : "sign.jpg",
                                        ],
                                        imageNames: [
                                          "Signature for Customer",
                                          "Signature for Shipper",
                                          "Signature for Driver",
                                        ],
                                        "Date For Customer":
                                          item?.dateforcustomer,
                                        "Time In Customer":
                                          item?.timeInforcustomer,
                                        "Time Out Customer":
                                          item?.timeOutfortrans,
                                        Draft: item.isDraft ? "Yes" : "No",
                                        formtype: "Transportation",
                                      };
                                      dispatch(generateTransPdf(newData, item?.id, item?.ticketNumber));
                                    }}
                                  />
                                </div>
                              </th>
                            </tr>
                          </>
                        );
                      }
                    )}
                  </table>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransportationTicketsTable;
