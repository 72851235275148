import { Images } from "./imsges";

export const optionList = [
  { value: "red", label: "Red" },
  { value: "green", label: "Green" },
  { value: "yellow", label: "Yellow" },
  { value: "blue", label: "Blue" },
  { value: "white", label: "White" },
];

export const jobList = [
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    jobtime: "6Pm",
    name: "John Doe",
    address: "30 Bond St, Toronto",
    equipment: "8 k Short Mast Forklift / Delsnd PU SCCI / Operator",
    riggers: "03",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  
];

export const selectNumber = [
  { id: "1", number: "1" },
  { id: "2", number: "2" },
  { id: "3", number: "3" },
  { id: "4", number: "4" },
  { id: "5", number: "5" },
];

export const AdminData = [
  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },  {
    serial: "01",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "02",
    adminName: "Jhon Doe",
    emialAddress: "johndoe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "03",
    adminName: "Andrew",
    emialAddress: "andrew@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "04",
    adminName: "Smith",
    emialAddress: "smith@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
];

export const Manager = [
  {
    serial: "01",
    adminName: "Doe",
    emialAddress: "doe@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "02",
    adminName: "grob",
    emialAddress: "grob@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "03",
    adminName: "northy",
    emialAddress: "northy@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "04",
    adminName: "mike",
    emialAddress: "mike@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
];

export const DailyUser = [
  {
    serial: "01",
    adminName: "Johnny",
    emialAddress: "Johnny@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "02",
    adminName: "Robert",
    emialAddress: "Robert@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "03",
    adminName: "James",
    emialAddress: "James@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
  {
    serial: "04",
    adminName: "Snouch",
    emialAddress: "Snouch@gmail.com",
    imageEidt: Images.edit,
    deleImage: Images.dele,
  },
];

export const riggerTicketsData = [
  {
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },
  {
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },{
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Equipment Hoist and Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },
  {
    specificationsAndRemarks: "Hoist and Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: "Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: "Hoist and Place Mechanical Equipment",
  },
  {
    specificationsAndRemarks: " Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: "Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: " Place Mechanical Equipment",
  },
  {
    specificationsAndRemarks: " Place Mechanical Equipment",
    customer: "Modern Niagara",
    location: "900 Bay St, T.O",
    poNumber: "-",
    date: "17 Oct 2022",
    startJob: "05:30",
    arrivalYard: "06:30",
    travelTime: "06:30",
    totalHours: "12 hrs",
    rating: "3.5",
    operation: "confirm",
    emailAddress: "Snouch@gmail.com",
    notesOthers: " Place Mechanical Equipment",
    signature: "https://signature.com",
    leaveYard: "05:20",
    finishJob: "10:20",
    lunch: "12",
    craneTime: "02:20",
    craneNumber: "12",
    boomLength: "190",
    otherEquipment: " Place Mechanical Equipment",
  },
];

export const TransportationData = [
  {
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },
  {
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },{
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },
  {
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },
  {
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },
  {
    pickupAddress: "2-99 Great Gulf Dr, Concord",
    billingAddress: "9-00 abc place, city or country",
    timeInfortrans: "12:09 pm",
    timeOutfortrans: "9:10 am",
    notes: "Hoist and Place Mechanical Equipment",
    specialInstructionsforjob: "Test name",
    poNumber: "1241Pl0",
    specialInstructionsforpo: "test care",
    siteContactName: "abc test",
    specialInstructionsforconName: "test_2 Texst",
    siteContactNumber: "908060-4040",
    specialInstructionsforconNo: "0909-090909",
    shipperName: "TEST",
    signatureforshipper: "https://google.com",
    dateforshipper: "12-05-2023",
    timeInforshipper: "10:20 am",
    timeOutforshipper: "12:50 pm",
    pickUpDriverName: "Test_3 test",
    signaturefordriver: "https://google.com",
    datefordriver: "13-09-2023",
    timeInfordriver: "1:08 pm",
    timeOutfordriver: "5:20 am",
    customerName: "Customer_test",
    customerEmail: "Customer@gmail.cm",
    signatureforcustomer: "https://google.com",
    dateforcustomer: "23-09-2023",
    timeInforcustomer: "10:09 am",
    timeOutforcustomer: "10:10pm",
  },
];

export const PayDutyFormDummyData = [
  {
    date: "09-18-2023",
    location: "Near Kharadar ",
    startTime: "7:12:am",
    endtime: "8:09 am",
    totalHours: "7hrs",
    email: "test@gmail.com",
    officerName: "Test",
    officer: "TEST",
    division: "123- aplha test",
    signature: "https://google.com",
  },
  {
    date: "09-18-2023",
    location: "Near Kharadar ",
    startTime: "7:12:am",
    endtime: "8:09 am",
    totalHours: "7hrs",
    email: "test@gmail.com",
    officerName: "Test",
    officer: "TEST",
    division: "123- aplha test",
    signature: "https://google.com",
  },
  {
    date: "09-18-2023",
    location: "Near Kharadar ",
    startTime: "7:12:am",
    endtime: "8:09 am",
    totalHours: "7hrs",
    email: "test@gmail.com",
    officerName: "Test",
    officer: "TEST",
    division: "123- aplha test",
    signature: "https://google.com",
  },
  {
    date: "09-18-2023",
    location: "Near Kharadar ",
    startTime: "7:12:am",
    endtime: "8:09 am",
    totalHours: "7hrs",
    email: "test@gmail.com",
    officerName: "Test",
    officer: "TEST",
    division: "123- aplha test",
    signature: "https://google.com",
  },
];
